var FE = (function(exports, $){

  "use strict";

  /**
   * Add properties to an object
   *
   * @param {Object} target The target object onto which the properties should be added
   * @param {Array}  props  The functions to be added as properties of the object
   * @private
   */
  function _defineProperties(target, props) {
    for (var i = 0, l = props.length; i < l; i++) {
      var descriptor = props[i];
      descriptor.enumerable = descriptor.enumerable || false;
      descriptor.configurable = descriptor.configurable || false;
      if ('value' in descriptor)
        descriptor.writable = true;
      Object.defineProperty(target, descriptor.key, descriptor);
    }
  }

  /**
   * Check if a function call is not made to the constructor
   *
   * @param {Object} instance
   * @param {Object} Constructor
   * @private
   */
  function _classCallCheck (instance, Constructor) {
    if (!(instance instanceof Constructor))
      throw new TypeError("Cannot call a class as a function");
  }

  var VarExistance = function() {

    return {

      isNull : function isNull( value ) {
        return ( value === null );
      },

      isEmpty : function isEmpty( value ) {
        return ( value === 0 || value === '' );
      },

      isUndefined : function isUndefined( value ) {
        return ( typeof value === 'undefined' );
      }

    };
  }();

  var PageOverlay = function() {

    var SELECTORS = {
      BODY      : 'html',
      CONTAINER : '#page-container'
      // OVERLAY   : '.overlay'
    };

    var CLASSES = {
      BODY      : 'has-overlay'
    };

    var INITIAL_STATUS = true;
    var SINGLETON = this;

    function PageOverlay() {
      this.SCROLLTOP = 0;
      return this;
    }

    if (!(SINGLETON instanceof PageOverlay))
      SINGLETON = new PageOverlay();

    SINGLETON.init = function init( initial_status ) {
      this.STATUS = ( typeof initial_status !== 'undefined' ) ? initial_status : INITIAL_STATUS;
      this.toggle();
    };

    var _proto = PageOverlay.prototype;

    _proto.show = function show() {
      // Lock the body
      if(window.pageYOffset) {
        this.SCROLLTOP = window.pageYOffset;

        $( SELECTORS.CONTAINER ).css({
          top: -(this.SCROLLTOP)
        });
      }
      $( SELECTORS.BODY ).addClass( CLASSES.BODY );
      this.STATUS = true;
    };

    _proto.hide = function hide() {
      var _this = this;
      $( SELECTORS.BODY ).removeClass( CLASSES.BODY );

      // Unlock the body
      $( SELECTORS.CONTAINER ).css({
        top: ''
      });

      window.scrollTo( 0, _this.SCROLLTOP );
      window.setTimeout(function () {
        _this.SCROLLTOP = null;
      }, 0);

      this.STATUS = false;
    };

    _proto.toggle = function toggle() {
      if( this.STATUS )
        this.hide();
      else
        this.show();
    };

    _defineProperties(PageOverlay, [
      {
        key: 'STATUS',
        /**
         * get status
         * @type {boolean}
         */
        get: function get() {
          return this._status;
        },
        /**
         * set status
         * @param {boolean} new_status
         */
        set: function set( new_status ) {
          if( new_status !== this._status ) {
            this._status = new_status;
            if( new_status )
              this.show();
            else
              this.hide();
          }
        }
      },
      {
        key: 'SCROLLTOP',
        /**
         * get scrollTop offset
         * @type {number}
         */
        get: function get() {
          return this._scrollTop;
        },
        /**
         * set scrollTop offset
         * @param {number} offset
         */
        set: function set( offset ) {
          this._scrollTop = offset;
        }
      }
    ]);

    return SINGLETON;
  }();

  var PageLoader = function() {

    var NAME = 'page-loader';
    var SELECTORS = {
      BODY      : 'html'
      // CONTAINER : '#page-container',
      // LOADER    : '#' + NAME,
      // CONTENT   : '#' + NAME + '-block',
      // ANIMATOR  : '#' + NAME + '-animation'
    };

    var CLASSES = {
      BODY      : 'has-loader'
    };

    var INITIAL_STATUS = true;
    var SINGLETON = this;

    function PageLoader() {
      return this;
    }

    if (!(SINGLETON instanceof PageLoader))
      SINGLETON = new PageLoader();

    SINGLETON.init = function init() {
      this.STATUS = !INITIAL_STATUS;
      this.toggle();
    };

    var _proto = PageLoader.prototype;

    _proto.hide = function hide() {
      $( SELECTORS.BODY ).removeClass( CLASSES.BODY );
      PageOverlay.hide();
      this.STATUS = false;
    };

    _proto.show = function show() {
      PageOverlay.show();
      $( SELECTORS.BODY ).addClass( CLASSES.BODY );
      this.STATUS = true;
    };

    _proto.toggle = function toggle() {
      if( this.STATUS )
        this.hide();
      else
        this.show();
    };

    _defineProperties(PageLoader, [
      {
        key: 'STATUS',
        /**
         * current
         * @type {string}
         */
        get: function get() {
          return this._status;
        },
        /**
         * current setter
         * @param {boolean} new_status
         */
        set: function set( new_status ) {
          this._status = new_status;
          if( new_status )
            this.show();
          else
            this.hide();
        }
      }
    ]);

    return SINGLETON;
  }();

  /*
  var LoadingSpinner = function() {

    var $el = $('#loading-spinner');


    function _constructor() {
      return this;
    }

    var show = function show() {

    };

  }();*/

  var ScrollToId = function() {

    function _constructor() {
      return this;
    }
    //var _proto = _constructor.prototype;

    var getTargetElement = function getTargetElement ( idOrName ){
      var $el,
        id,
        name;

      if( idOrName.substr(0, 1) === '#' ) {
        id   = idOrName;
        name = idOrName.substr(1);
      } else {
        id   = '#' + idOrName;
        name = idOrName;
      }

      $el = $( id );

      if( !$el.length )
        $el = $('[name="' + name + '"]');

      return $el;
    };

    /**
     * Scroll initiated by a trigger
     *
     * @param {HTMLElement|string} triggerSelector
     * @param {string} targetElementId
     * @param {Integer|number} duration
     * @param {Function|undefined} callback
     * @param {string|undefined} triggerEvents='click'
     */
    _constructor.fromTrigger = function fromTrigger( triggerSelector, targetElementId, duration, callback, triggerEvents ) {
      var _this = this;

      if( typeof triggerEvents === 'undefined' )
        triggerEvents = 'click';

      $( triggerSelector ).on( triggerEvents, function( event ){
        _this.onCall( targetElementId, duration, callback, event );
      });
    };

    /**
     * Scroll to element
     *
     * @param {string} targetElementId
     * @param {Integer|number} duration
     * @param {Function|undefined} callback
     * @param {Event|undefined} event
     */
    _constructor.onCall = function onCall( targetElementId, duration, callback, event ) {
      var $el = getTargetElement( targetElementId );

      // If target element doesn't exist just return and stop processing this function
      if (!$el.length)
        return;

      if( typeof duration === 'undefined' )
        duration = 300;

      if( typeof event !== 'undefined' )
        event.preventDefault();

      // Only animate when DOM is ready
      $(function(){

        $('html, body').animate(
          {
            scrollTop: $el.offset().top
          },
          duration,
          function() {

            $el.focus();

            if ($el.is(":focus")) {
              // Check if the target was focused
              return false;
            } else {
              // Add tabindex for elements not focusable
              $el.attr('tabindex','-1');

              // Set focus again
              $el.focus();
            }

            // Call custom callback (if set)
            if( typeof callback !== 'undefined' )
              (callback)();
          }
        );
      });

    };

    return _constructor;
  }();

  // --------------------------------------------------------------------------------
  // INIT SINGLETONS
  // --------------------------------------------------------------------------------

  PageLoader.init();

  exports.ScrollToId    = ScrollToId;
  exports.PageOverlay   = PageOverlay;
  exports.PageLoader    = PageLoader;
  exports.VarExistance  = VarExistance;

  $(function(){
    PageLoader.hide();
  });

  return exports;

})({}, jQuery);


(function($){

  "use strict";

  /**
   * MMI SNI Input Number Formatter
   *
   * @param {object} settings
   * @returns {jQuery}
   */
  $.fn.sni_numberFormatter = function( settings ){
    var $_this = this,
      options,
      uDecimalCharacter,
      uThousandsCharacter;
    var utility_unicode_char = function( character ) {
      return ( '\\u' + ( '0000' + ( character.charCodeAt(0).toString(16) ) ).slice(-4) );
    };

    settings = settings || {};

    options = $.extend({
      decimal_places: 2,
      char_decimal:   '.',
      char_thousands: ','
    }, settings );

    settings.decimal_places = $_this.data('currency_decimal_places') || settings.decimal_places;
    settings.char_decimal   = $_this.data('currency_char_decimal')   || settings.char_decimal;
    settings.char_thousands = $_this.data('currency_char_thousands') || settings.char_thousands;

    uDecimalCharacter = utility_unicode_char( options.char_decimal );
    uThousandsCharacter = utility_unicode_char( options.char_thousands );

    var formatter = function( number ) {
      var integer_part,
        decimal_part,
        tempNumber;
      number += '';

      if ( !number.length )
        return number;

      number = number.replace( new RegExp( uDecimalCharacter, 'g' ), '.' )
        .replace( new RegExp( uThousandsCharacter, 'g' ), '' )
        .replace( new RegExp( '[^0-9+-Ee.]', 'g' ), '' );

      integer_part = Math.round( number ) + '';
      decimal_part = '';

      if ( options.decimal_places ) {
        tempNumber = number.split('.');
        integer_part = tempNumber[0];
        decimal_part = tempNumber[1];
      }

      if( integer_part.length > 3 )
        integer_part = integer_part.replace( /\B(?=(?:\d{3})+(?!\d))/g, options.char_thousands );

      if( decimal_part.length < options.decimal_places && options.decimal_places > 0 )
        decimal_part = options.char_decimal + ( parseInt(decimal_part) ).toFixed( options.decimal_places );

      return integer_part + decimal_part;
    };

    $_this.on( 'keyup blur', function( event ) {
      var $input = $( event.currentTarget );
      $input.val( formatter( $input.val() ) );
    });

    return $_this;
  };

  // let $currencyInputs = $('input.mask-currency');
  // if( $currencyInputs.length ) {
  //   $currencyInputs.each(function( i , input ) {
  //     $(input).sni_numberFormatter();
  //   });
  // }

})(jQuery);